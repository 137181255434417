import React from "react";
import {View, StyleSheet,Text } from '@react-pdf/renderer';
const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 20
const COL2_WIDTH = 20
const COLN_WIDTH = 15
const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginTop: 20
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableCol2Header: { 
      width: COL2_WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },  
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol2: { 
      width: COL2_WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  }
});

const Pip = ({data}) => (
      <View>
            <Text style={'marginTop: 20'}>Pipework System Contents</Text>
      <View style={styles.table}> 
        {data.Pipe[0] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1Header}> 
            <Text style={styles.tableCellHeader}>Product</Text> 
          </View> 
          <View style={styles.tableCol2Header}> 
            <Text style={styles.tableCellHeader}>Code</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Option</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Individual Price</Text> 
          </View>
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Quantity</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Cost</Text> 
          </View> 
        </View>}
        {data.Pipe[0] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[0]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[0]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[0]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[0][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[0]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
               <Text style={styles.tableCell}>{data.Pipe[0]['Cost']}</Text> 
          </View> 
        </View>}
        {data.Pipe[1] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[1]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[1]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[1]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[1][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[1]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
               <Text style={styles.tableCell}>{data.Pipe[1]['Cost']}</Text> 
          </View> 
        </View>} 
        {data.Pipe[2] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[2]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[2]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[2]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[2][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[2]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
               <Text style={styles.tableCell}>{data.Pipe[2].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[3] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[3]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[3]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[3]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[3][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[3]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[3].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[4] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[4]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[4]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[4]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[4][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[4]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[4].Cost}</Text> 
          </View> 
        </View>}
        {data.Pipe[5] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[5]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[5]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[5]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[5][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[5]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[5].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[6] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[6]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[6]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[6]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[6][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[6]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[6].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[7] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[7]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[7]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[7]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[7][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[7]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[7].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[8] && <View style={styles.tableRow}>
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[8]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[8]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[8]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[8][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[8]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[8].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[9] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[9]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[9]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[9]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[9][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[9]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[9].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[10] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[10]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[10]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[10]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[10][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[10]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[10].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[11] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[11]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[11]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[11]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[11][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[11]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[11].Cost}</Text> 
          </View> 
        </View>}
        {data.Pipe[12] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[12]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[12]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[12]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[12][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[12]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[12].Cost}</Text> 
          </View> 
        </View>}
        {data.Pipe[13] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[13]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[13]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[13]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[13][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[13]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[13].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[14] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[14]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[14]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[14]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[14][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[14]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[14].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[15] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[15]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[15]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[15]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[15][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[15]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[15].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[16] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[16]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[16]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[16]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[16][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[16]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[16].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[17] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[17]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[17]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[17]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[17][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[17]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[17].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[18] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[18]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[18]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[18]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[18][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[18]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[18].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[19] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[19]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[19]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[19]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[19][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[19]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[19].Cost}</Text> 
          </View> 
        </View>} 
        {data.Pipe[20] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[20]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[20]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[20]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[20][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[20]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[20].Cost}</Text> 
          </View> 
        </View>}
        {data.Pipe[21] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Pipe[21]['Product Name']}</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{data.Pipe[21]['Code ']}</Text> 
          </View> 
          <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{data.Pipe[21]['Option ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[21][' Price  ']}</Text> 
          </View>
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[21]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Pipe[21].Cost}</Text> 
          </View> 
        </View>} 

      </View>
      </View>

      
);

export default Pip;
//ReactPDF.render(<Quixote />);