import React from "react";
import {View, StyleSheet,Text} from '@react-pdf/renderer';
const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 20
const COL4_WIDTH = 15
const COLN_WIDTH = (100 - COL1_WIDTH - COL4_WIDTH) / 2
const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableCol4Header: { 
    width: COL4_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },  
  tableCol4: { 
    width: COL4_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 2, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 1, 
    fontSize: 10
  }
});

const Quixote = ({data}) => (
      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableCol1Header}> 
            <Text style={styles.tableCellHeader}>Product</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Components</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Component Data</Text> 
          </View> 
          <View style={styles.tableCol4Header}> 
            <Text style={styles.tableCellHeader}>Price</Text> 
          </View> 
        </View>
        {(data.Pool['SERIES '] == 'Series 3.1') && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Pool</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Series</Text>
            <Text style={styles.tableCell}>Pool Type</Text>
            <Text style={styles.tableCell}>Width</Text>
            <Text style={styles.tableCell}>Length</Text>
            <Text style={styles.tableCell}>Panel Height</Text>
            <Text style={styles.tableCell}>Surface Area M^2</Text>
            <Text style={styles.tableCell}>Water Volume M^3</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.Pool["SERIES "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["POOL TYPE"]}</Text>
            <Text style={styles.tableCell}>{data.Pool["WIDTH "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["Length "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["Total Depth "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["WIDTH "] * data.Pool["Length "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["Water Volume M^3"]}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Pool[" Manufacture Cost "]}</Text> 
          </View> 
        </View>}
        {(data.Pool['SERIES '] == 'Series 1.1') && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Pool</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Series</Text>
            <Text style={styles.tableCell}>Pool Type</Text>
            <Text style={styles.tableCell}>Width</Text>
            <Text style={styles.tableCell}>Length</Text>
            <Text style={styles.tableCell}>Panel Height</Text>
            <Text style={styles.tableCell}>Surface Area M^2</Text>
            <Text style={styles.tableCell}>Water Volume M^3</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.Pool["SERIES "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["POOL TYPE"]}</Text>
            <Text style={styles.tableCell}>{data.Pool["Width "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["Length "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["Panel Height "]}</Text>
            <Text style={styles.tableCell}>{data.Pool["Surface Area M^2"]}</Text>
            <Text style={styles.tableCell}>{data.Pool["Water Volume M^3"]}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Pool[" Manufacture Cost inc Fittings  "]}</Text> 
          </View> 
        </View>}
        {data.Pump && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Pump</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Supplier</Text>
            <Text style={styles.tableCell}>Make</Text>
            <Text style={styles.tableCell}>Model</Text>
            <Text style={styles.tableCell}>Flow Rate M^3/H</Text>
            <Text style={styles.tableCell}>KW</Text>
            <Text style={styles.tableCell}>Connections</Text>
            <Text style={styles.tableCell}>Phase</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.Pump.Supplier}</Text>
            <Text style={styles.tableCell}>{data.Pump.Make}</Text>
            <Text style={styles.tableCell}>{data.Pump.Model}</Text>
            <Text style={styles.tableCell}>{data.Pump['Flow Rate M^3']}</Text>
            <Text style={styles.tableCell}>{data.Pump.KW}</Text>
            <Text style={styles.tableCell}>{data.Pump.Connections}</Text>
            <Text style={styles.tableCell}>{data.Pump.Phase}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Pump[' Cost ']}</Text> 
          </View> 
        </View>}
        {data.Filter && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Filter</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Application</Text>
            <Text style={styles.tableCell}>Supplier</Text>
            <Text style={styles.tableCell}>Make</Text>
            <Text style={styles.tableCell}>Model</Text>
            <Text style={styles.tableCell}>Flow Rate M^3/H</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.Filter.Application}</Text>
            <Text style={styles.tableCell}>{data.Filter.Supplier}</Text>
            <Text style={styles.tableCell}>{data.Filter.Make}</Text>
            <Text style={styles.tableCell}>{data.Filter.Model}</Text>
            <Text style={styles.tableCell}>{data.Filter['Flow Rate M^3/H']}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Filter[' FILTER COST ']}</Text> 
          </View> 
        </View>}
        {data.heating && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Heating</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Heat Source</Text>
            <Text style={styles.tableCell}>Supplier</Text>
            <Text style={styles.tableCell}>Make</Text>
            <Text style={styles.tableCell}>Model</Text>
            <Text style={styles.tableCell}>Type</Text>
            <Text style={styles.tableCell}>Outdoor Pool M^3</Text>
            <Text style={styles.tableCell}>Indoor Pool M^3</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.heating['Heat Source']}</Text>
            <Text style={styles.tableCell}>{data.heating.Supplier}</Text>
            <Text style={styles.tableCell}>{data.heating.Make}</Text>
            <Text style={styles.tableCell}>{data.heating.Model}</Text>
            <Text style={styles.tableCell}>{data.heating.Type}</Text>
            <Text style={styles.tableCell}>{data.heating['Outdoor Pool M^3']}</Text>
            <Text style={styles.tableCell}>{data.heating['Indoor Pool M^3']}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.heating[' Cost ']}</Text> 
          </View> 
        </View>}
        {data.uv && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>UV System</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Application</Text>
            <Text style={styles.tableCell}>Supplier</Text>
            <Text style={styles.tableCell}>Make</Text>
            <Text style={styles.tableCell}>Model</Text>
            <Text style={styles.tableCell}>Flow Rate M^3/H</Text>
            <Text style={styles.tableCell}>UV-C DOSE mJ/cm^2</Text>
            <Text style={styles.tableCell}>KW</Text>
            <Text style={styles.tableCell}>Phase</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.uv.Application}</Text>
            <Text style={styles.tableCell}>{data.uv.Supplier}</Text>
            <Text style={styles.tableCell}>{data.uv.Make}</Text>
            <Text style={styles.tableCell}>{data.uv.Model}</Text>
            <Text style={styles.tableCell}>{data.uv['Flow Rate M^3/H']}</Text>
            <Text style={styles.tableCell}>{data.uv['UV-C DOSE mJ/cm^2']}</Text>
            <Text style={styles.tableCell}>{data.uv.KW}</Text>
            <Text style={styles.tableCell}>{data.uv.PHASE}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.uv[' COST ']}</Text> 
          </View> 
        </View>}
        {data.Access && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Pool Access</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Component</Text>
            <Text style={styles.tableCell}>{'\n'}</Text>
            <Text style={styles.tableCell}>Type</Text>
            <Text style={styles.tableCell}>Depth</Text>
           
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.Access.Product}</Text>
            <Text style={styles.tableCell}>{data.Access.Type}</Text>
            <Text style={styles.tableCell}>{data.Access.Depth}</Text>
            
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Access.Cost}</Text> 
          </View> 
        </View>}
        {(data.Pool['SERIES '] == 'Series 1.1') && data.cover && data.motor && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Cover System</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Motor</Text>
            <Text style={styles.tableCell}>Width</Text>
            <Text style={styles.tableCell}>Position</Text>
            <Text style={styles.tableCell}>Type</Text>
            <Text style={styles.tableCell}>{'\n'}</Text>
            <Text style={styles.tableCell}>Colour</Text>
            <Text style={styles.tableCell}>Price M^2</Text>
           
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.motor.Motor}</Text>
            <Text style={styles.tableCell}>{data.motor.Width}</Text>
            <Text style={styles.tableCell}>{data.motor.Position}</Text>
            <Text style={styles.tableCell}>{data.cover.Type}</Text>
            <Text style={styles.tableCell}>{'\n'}</Text>
            <Text style={styles.tableCell}>{data.cover.Colour}</Text>
            <Text style={styles.tableCell}>{data.cover['Price m^2']}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{Math.round(data.motor.Cost + (data.Pool["Surface Area M^2"] * data.cover['Price m^2']))}</Text> 
          </View> 
        </View>}
        {(data.Pool['SERIES '] == 'Series 3.1') && data.cover && data.motor && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Cover System</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Motor</Text>
            <Text style={styles.tableCell}>Width</Text>
            <Text style={styles.tableCell}>Position</Text>
            <Text style={styles.tableCell}>Type</Text>
            <Text style={styles.tableCell}>{'\n'}</Text>
            <Text style={styles.tableCell}>Colour</Text>
            <Text style={styles.tableCell}>Price M^2</Text>
           
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.motor.Motor}</Text>
            <Text style={styles.tableCell}>{data.motor.Width}</Text>
            <Text style={styles.tableCell}>{data.motor.Position}</Text>
            <Text style={styles.tableCell}>{data.cover.Type}</Text>
            <Text style={styles.tableCell}>{'\n'}</Text>
            <Text style={styles.tableCell}>{data.cover.Colour}</Text>
            <Text style={styles.tableCell}>{data.cover['Price m^2']}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{Math.round(data.motor.Cost + ((data.Pool["WIDTH "] * data.Pool["Length "])* data.cover['Price m^2']))}</Text> 
          </View> 
        </View>}
        {data.Lining && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Lining</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Material</Text>
            <Text style={styles.tableCell}>Total Material</Text>
            <Text style={styles.tableCell}>Lining Roll Quantity</Text>
            <Text style={styles.tableCell}>Quantity Weld on Profile</Text>
            <Text style={styles.tableCell}>Total Weld on Profile 15mm x 85mm x 2000mm</Text>
            <Text style={styles.tableCell}>Qty Liquid PVC 1L</Text>
            <Text style={styles.tableCell}>Qty Applicator Bottles</Text>
            <Text style={styles.tableCell}>Qty Contact Adhesive 5L</Text>
            <Text style={styles.tableCell}>Qty Spray Adhesive (Box of 12)</Text>
            <Text style={styles.tableCell}>Qty 4mm Felt Underlay 2m x 25m rolls</Text>
            <Text style={styles.tableCell}>Qty Underlay tape 50mm x50m</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.Lining['Material']}</Text>
            <Text style={styles.tableCell}>{data.Lining['Total Material']}</Text>
            <Text style={styles.tableCell}>{data.Lining['Lining Roll Quantity']}</Text>
            <Text style={styles.tableCell}>{data.Lining['Quantity Weld on Profile']}</Text>
            <Text style={styles.tableCell}>{data.Lining['Total Weld on Profile 15mm x 85mm x 2000mm ']}</Text>
            <Text style={styles.tableCell}>{'\n'}</Text>
            <Text style={styles.tableCell}>{data.Lining['Quantity Liquid PVC 1L']}</Text>
            <Text style={styles.tableCell}>{data.Lining['Quantity Applicator Bottles ']}</Text>
            <Text style={styles.tableCell}>{data.Lining['Quantity Contact Adhesive 5L ']}</Text>
            <Text style={styles.tableCell}>{data.Lining['Quantity Spray Adhesive (Box of 12) ']}</Text>
            <Text style={styles.tableCell}>{data.Lining['Quantity 4mm Felt Underlay 2m x 25m rolls ']}</Text>
            <Text style={styles.tableCell}>{'\n'}</Text>
            <Text style={styles.tableCell}>{data.Lining['Quantity Underlay tape 50mm x50m']}</Text>
            
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Lining['Total Cost']}</Text> 
          </View> 
        </View>}
        {data.Dosing && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Dosing System</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Application</Text>
            <Text style={styles.tableCell}>Supplier</Text>
            <Text style={styles.tableCell}>Make</Text>
            <Text style={styles.tableCell}>Model</Text>
            <Text style={styles.tableCell}>Pool Max Volume  M^3</Text>
            <Text style={styles.tableCell}>Phase</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.Dosing.Application}</Text>
            <Text style={styles.tableCell}>{data.Dosing.Supplier}</Text>
            <Text style={styles.tableCell}>{data.Dosing.Make}</Text>
            <Text style={styles.tableCell}>{data.Dosing['Model No:']}</Text>
            <Text style={styles.tableCell}>{data.Dosing['Pool Max Volume  M^3']}</Text>
            <Text style={styles.tableCell}>{data.Dosing.Phase}</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Dosing[' Cost ']}</Text> 
          </View> 
        </View>}
        {data.Kit && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Maintainance Kit</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Kit Type</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Standard</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Kit[13][' Cost  ']}</Text>
          </View> 
        </View>}
        {data.Pipe[21] && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Pipework System</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Pool Type</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Deck</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Pipe[21].Cost}</Text> 
          </View> 
        </View>}
        {!data.Pipe[21] && data.Pipe[14] && <View style={styles.tableRow}> 
        <View style={styles.tableCol1}> 
            <Text style={styles.tableCell}>Pipework System</Text> 
          </View> 
          <View style={styles.tableCol}> 
            <Text style={styles.tableCell}>Pool Type</Text>
          </View> 
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Skimmer</Text>
          </View>
          <View style={styles.tableCol4}> 
            <Text style={styles.tableCell}>{data.Pipe[14].Cost}</Text> 
          </View> 
        </View>}
             </View>

      
);

export default Quixote;
//ReactPDF.render(<Quixote />);