import React from 'react';
import {Page, Document, StyleSheet,View } from '@react-pdf/renderer';
import InvoiceTitle from './title'
import BillTo from './bill'
import Quixote from './table'
import Pip from './Pipes'
import Kits from './Kit'
import Sum from './summary'


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    view: {
        marginTop:20
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });

    let Invoice = ({invoice}) => (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle title='Castillo'/>
                <BillTo invoice={invoice}/>
                
                <View style={styles.view}>
                    <Sum data={invoice}/>
                </View>
              
                <Quixote data ={invoice}/>

                <View style={styles.view}>
                    <Kits data={invoice}/>
                </View>
                <View style={styles.view}>
                    <Pip data={invoice}/>
                </View>
                
            </Page>
            
            
        </Document>
    );
 
  


export default Invoice
  
  