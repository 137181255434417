import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from './Button';
export default function BasicTextFields({ title, setPassword, setEmail, handleAction }) {
    return (
        <div>
            <div className="heading-container">
                <h3 style={{color:'white',fontSize:30}}>
                    {title}
                </h3>
            </div>

            <Box 
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >   <h4 style={{color:'white',fontSize:20}}>Email</h4>
                <TextField style={{backgroundColor:'white'}}
                    id="email"
                    color='warning'
                   
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <h4 style={{color:'white',fontSize:20,display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',}}>Password</h4>
                <TextField style={{backgroundColor:'white'}}
                    id="password"
                   
                    variant="outlined"
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Box>

            <Button title={title} handleAction={handleAction}/>
        </div>
    );
}
