import React, { useState, useEffect } from 'react';
import './App.css';
import Form from './Components/Common/Form';
import axios from "axios";
import { useForm } from "react-hook-form";
import QuoteHome from './Components/QuoteHome/frontend/src/index';
import { PDFViewer } from '@react-pdf/renderer'
import Invoice from './Components/QuoteHome/frontend/src/components/pdf/quote'
import {
	Routes,
	Route,
	useNavigate
} from "react-router-dom";
import { app } from './firebase-config';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Switch } from '@mui/material';


//Define Production URL here
function App() {
	let url = ''
	if (process.env.NODE_ENV == 'development') {
		// url = 'http://localhost:3001';
		url = process.env.REACT_APP_URL_DEV;
	}
	else {
		// url = 'http://castillopoolpricer.teller.tdrnetworks.com';
		url = process.env.REACT_APP_URL_PRO;
	}

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setLoading] = React.useState(false);
	const [pool, setpool] = React.useState();
	const [gear,setgear] = React.useState(0);
	const [series,setseries] = React.useState(false);
	const [intro, notintro] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [det,notdet] = React.useState(false);
	const [loc,notloc] = React.useState(false);
	const [access,notaccess] = React.useState(false);
	const [lining,notlining] = React.useState(false);
	const [type,nottype] = React.useState(false);
	const [dim,notdim] = React.useState(false);
	const [use,notuse] = React.useState(false);
	const [usage,notusage] = React.useState(false);
	const [cover,notcover] = React.useState(false);
	const [uv,notuv] = React.useState(false);
	const [heat,notheat] = React.useState(false);
	const [dose,notdose] = React.useState(false);
	const [cave,setcave] = React.useState(false);
	const [pvc,setpvc] = React.useState(true);
	const [material,setmaterial] = React.useState(false);
	const [gearboth,setgearboth] = React.useState(false);

	let navigate = useNavigate();
	useEffect(() => {
		let authToken = sessionStorage.getItem('Auth Token')
		if (authToken) {
			navigate('/home')
		}
	}, [])
	const { register, watch, handleSubmit } = useForm();
	const onSubmit = (data) => {
		const val = (JSON.stringify(data));
		console.log(val);
		try {
			const local_data = axios.post((url + '/finishedForm'), { val })
		} catch (error) {
			console.log(error.response.data)
		}

	};
	const viewQuote = () => {

		try {
			axios.get((url + '/retrievedata')).then(
				response => {
					console.log({response});
					if (response.data != null) {
						if (response.data.Pipe != null) {
							try {
								setpool((response.data));
								setLoading(false);
								notintro(true)
							}
							catch (error) {
								notintro(false)
								console.log(error.response)
							}
						}
						else {
							notintro(false)
						}

					}
					else {
						notintro(false)
					}


				})
		}
		catch (error) {
			console.log(error.response)
		}


		if (isLoading) {
			return (
				<div>
					Loading
				</div>
			)
		}
	}



	const handleAction = (id) => {
		const authentication = getAuth();
		if (id === 1) {
			signInWithEmailAndPassword(authentication, email, password)
				.then((response) => {
					navigate('/home')
					sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
				})
				.catch((error) => {
					console.log(error.code)
					if (error.code === 'auth/wrong-password') {
						toast.error('Please check the Password');
					}
					if (error.code === 'auth/user-not-found') {
						toast.error('Please check the Email');
					}
				})
		}
		if (id === 2) {
			createUserWithEmailAndPassword(authentication, email, password)
				.then((response) => {
					navigate('/home')
					sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken)
				})
				.catch((error) => {
					if (error.code === 'auth/email-already-in-use') {
						toast.error('Email Already in Use');
					}
				})
		}
	}


	return (
		<div className="App">
			<>
				<ToastContainer />
				<Routes>
					<Route
						path='login'
						element={
							<Form
								title="Login"
								setEmail={setEmail}
								setPassword={setPassword}
								handleAction={() => handleAction(1)}
							/>}
					/>
					<Route
						path='/'
						element={
							<Form
								title="Login"
								setEmail={setEmail}
								setPassword={setPassword}
								handleAction={() => handleAction(1)}
							/>}
					/>
					<Route
						path='/register'
						element={
							<Form
								title="Register"
								setEmail={setEmail}
								setPassword={setPassword}
								handleAction={() => handleAction(2)}
							/>}
					/>
					<Route
						path='/home'
						element={
							<div>

								{<div>

									<div style={{ fontWeight: 'bold', fontSize: 40, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										Introduction
									</div >&nbsp;

									<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										This tool is designed to provide you with an estimate of the cost of the key equipment needed to
										build your swimming pool.
									</div >&nbsp;

									<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										This estimate is not a quote for full installation. It does not include labour costs for pool installation
										(including electrical and mains water connections) , excavation, concrete base ,landscaping or in the
										case of indoor pools the supply and installation of the pool hall environmental management
										equipment required which will be bespoke to the pool hall design . A quotation will require a site
										visit and access to detailed pool hall plans for your indoor pool.
									</div >&nbsp;

									<div style={{ fontWeight: 'bold', fontSize: 40, textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										How To Use This Tool
									</div >&nbsp;

									<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										You will be presented with a series of choices or options to select including:
									</div >&nbsp;

									<div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<ul>
											<li>Pool Shell Finish: Luxury Liner or Stainless Steel finish</li>
											<li>Pool Shell Type: Skimmer or Deck level (with or without cover cave)</li>
											<li>Pool Shell Size: Width, Length and Depth</li>
											<li>Pool Location: Outdoor or indoor </li>
											<li>Pool Use: Domestic , Commercial or Therapy. </li>
										</ul>
									</div >&nbsp;

									<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										From this information the software is programmed to select the appropriate pool plant room
										equipment options that will ensure that the water in your pool is safe and fully compliant with
										SPATA and PWTAG requirements.
									</div >&nbsp;

									<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										Some items will have an info logo. This will provide you with more information on the item to help
										you make an informed selection.
									</div >
									<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										<button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => setVisible(true)}>Begin</button>
									</div>

									<div>

										{visible && <form onSubmit={handleSubmit(onSubmit)}>

											<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="firstName">First Name</label>
											<input placeholder="" {...register("firstName")} />

											<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="lastName">Last Name</label>
											<input placeholder="" {...register("lastName")} />

											<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="email">Email</label>
											<input placeholder="bluebill1049@hotmail.com" type="email" {...register("email")} />

											<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="number">Contact Number</label>
											<input placeholder="" type="phonenumber" {...register("number")} />


											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="location">Pool Location</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notloc(!loc)}></input>
												</div>
											</div>

											<select {...register("location")}>
												<option value="Indoor">Indoor</option>
												<option value="Outdoor">Outdoor</option>
											</select>

											{loc && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Whether your pool is indoors or outdoors impacts on the equipment required to heat the
													water and in the case of an indoor pool the equipment required to manage the pool hall
													environment to ensure the fabric of the building is protected and the hall is comfortable for
													pool users. If your pool is to be located indoors this estimate does not include the
													environmental management system required as this will depend on the buildings design,
													volume and the use of glass.
												</div >&nbsp;
											</div>
											)}

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="dimension">Pool Dimension</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notdim(!dim)}></input>
												</div>
											</div>

											{!series && (<div><select {...register("dimension")}>
												<option value="3_5">3m x 5m</option>
												<option value="3_6">3m x 6m</option>
												<option value="4_6">4m x 6m</option>
												<option value="4_8">4m x 8m</option>
												<option value="4_9">4m x 9m</option>
												<option value="5_10">5m x 10m</option>
												<option value="5_12">5m x 12m</option>
												<option value="6_12">6m x 12m</option>
											</select></div>)}
											{series && (<div><select {...register("dimension")}>
												<option value="3_5">3m x 5m</option>
												<option value="4_6">4m x 6m</option>
												<option value="4_8">4m x 8m</option>
												<option value="4_9">4m x 8m</option>
												<option value="4_10">4m x 10m</option>
												<option value="4_10">4m x 12m</option>
											</select></div>)}

											{dim && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Please select from the standard sizes. Bespoke sizes and depths are available.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													To find out more about the difference between skimmer and deck level pools click on the info icon in Pool Type section.
												</div >&nbsp;
											</div>
											)}

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="series">Pool Build</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notdet(!det)}></input>
												</div>
											</div>

											<select {...register("series")}>
												<option onClick={() => setseries(false)} value="s1">Series 1 Panel System: Liner Finish</option>
												<option onClick={() => setseries(false)} value="s2">Series 2 Panel System</option>
												<option onClick={() => setseries(true)} value="s3">Series 3 One Piece Stainless Steel</option>
											</select>

											<div>
												{det && (<div>
													<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														Series 1 Panel System: Liner Finish: The 304 grade stainless steel panels are assembled on
														site and fixed to a concrete base and made watertight with a high quality liner which is
														fitted and welded on site.
													</div >&nbsp;
													<div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														Liner Choices include:
														<ul>
															<li>Block Colour</li>
															<li>Mosaic Pattern)</li>
															<li>Premium finishes including stone effect.</li>
														</ul>
													</div >&nbsp;

													<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														Series 2 Panel System : Stainless Steel Finish: 316 grade stainless steel panel system is
														assembled with a stainless steel floor and welded on site with seamed wall and seamless
														floor welds with a random orbital stainless steel finish.
													</div >&nbsp;

													<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
														Series 3 One Piece Stainless Steel: 316 grade stainless steel one piece pool is fabricated and
														and welded off site with seamless floor and wall welds in a selection of matt and polished
														stainless steel finishes.
													</div >&nbsp;
												</div>
												)}
											</div>

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="type">Pool Type</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => nottype(!type)}></input>
												</div>
											</div>

											<select {...register("type")}>
												{!cave && <option value="Skim_135">Skimmer 1.35m Depth</option>}
												{!cave && <option value="Skim_150">Skimmer 1.50m Depth</option>}
												<option value="Skca_135">Skimmer and Cave 1.35m Depth</option>
												<option value="Skca_150">Skimmer and Cave 1.50m Depth</option>
												{!cave && <option value="Deck_120">Deck 1.20m Depth</option>}
												{!cave && <option value="Deck_140">Deck 1.40m Depth</option>}
												<option value="Deca_120">Deck and Cave 1.20m Depth</option>
												<option value="Deca_140">Deck and Cave 1.40m Depth</option>
											</select>


											{type && (<div>
												<div style={{ fontWeight: 'bold', fontSize: 20, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													We offer pools in skimmer and deck level formats.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Skimmer pools have a freeboard between the top of the water and the top of the pool wall which is
													designed to contain the water within the pool shell. This is the most popular domestic installation as
													it is easier to install and maintain.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													The pool walls are 1.35 high with water depth of 1.2 m or 1.5m high with a water depth of 1.35m.
												</div >&nbsp;&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Deck level pools do not contain the water within the shell. The water is filled to the top and cascades
													into a channel where it is piped into a balance tank before entering the filtration system. The
													channel and balance tank makes deck level pools more expensive to install. They also require more
													maintenance. If you select a deck level pool a balance tank will be required which is not covered in
													this estimate.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													The standard pool walls and water depth options for deck level pools are 1.2 or 1.4m.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Panel system pools in both deck level and skimmer formats can be installed with deeper or
													shallower ends if required.
												</div >&nbsp;
											</div>
											)}

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="location">Pool Access</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notaccess(!access)}></input>
												</div>
											</div>

											<select {...register("access")}>
												<option value="Step">Steps</option>
												<option value="Ladder">Ladder</option>
											</select>

											{series && access && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Our in pool steps are ergonomically  designed to maximise  valuable swim space while providing safe and easy access to the pool. The pool steps are pre-fabricated from 316 stainless steel.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													316 grade stainless steel undercover steps with handrails. The steps are submerged and unattached to the handrails which are mounted on the pool deck to ensure smooth opening and closing of the pool cover.
												</div >&nbsp;
											</div>
											)}
											{!series && access && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Our in pool steps are ergonomically  designed to maximise  valuable swim space while providing safe and easy access to the pool. The pool steps are pre-fabricated from robust polypropylene.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Robust polypropylene undercover steps with handrails. The steps are submerged and unattached to the handrails which are mounted on the pool deck to ensure smooth opening and closing of the pool cover.
												</div >&nbsp;
											</div>
											)}


											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="usage">Pool Usage</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notuse(!use)}></input>
												</div>
											</div>

											<select {...register("usage")}>
												<option onClick={() => notusage(false)} value="Domestic">Domestic</option>
												<option onClick={() => notusage(true)} value="Commercial">Commercial</option>
												<option onClick={() => notusage(true)} value="spa">Spa and/or Therapy</option>
											</select>

											{use && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Different pool uses require different turnover rates which is the period of time the total volume of water is circulated through the filtration system ensuring that the water is safe. The selection of plant room equipment such as filters and pumps is determined by the turnover rate.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													In domestic pools where the number of bathers and pool use is low the turnover rate is usually 4-6 hours. Commercial pools where the number of bathers and hours of operation is greater the turnover rate is usually 2 hours. Spas and therapy pools where the water is warmer which increases the risk of infection require turnover rates of  45 minutes.
												</div >&nbsp;
											</div>
											)}

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="usage">Lining Selector</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notlining(!lining)}></input>
												</div>
											</div>

											<select {...register("lining")}>
												{!usage && <option value="Plain Lacquered">Plain Lacquered</option>}
												{!usage && <option value="Full Pattern Lacquered">Full Pattern Lacquered</option>}
												{!usage && <option value="3D Matrix - Premium 3D">3D Matrix - Premium 3D</option>}
												{!usage && <option value="StoneFlex - Premium 3D">StoneFlex - Premium 3D</option>}
												{(!usage || usage) && <option value="Elvaflex - Commercial Plain Colour">Elvaflex - Commercial Plain Colour</option>}
											</select>

											{lining && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													OPTION 1: PROFLEX LAQUERED SOLID COLOUR. 12 YEAR WARRANTY with stain resistance and chlorine lightfastness guarantees. Suitable for Temps up to 34c.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													OPTION 2: PROFLEX FULL PATTERN LAQUERED. 12 YEAR WARRANTY with stain resistance and chlorine lightfastness guarantees. Suitable for Temps up to 34c.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													OPTION 3: PROFLEX 3D MATRIX PREMIUM 3D COLLECTION. Look and feel of real mosaic tiles. 12 YEAR WARRANTY with stain resistance and chlorine lightfastness guarantees. Suitable for Temps up to 34c.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													OPTION 4: STONEFLEX - PREMIUM 3D COLLECTION. Unique stone like finish and texture. 15 YEAR WARRANTY with stain resistance and chlorine lightfastness guarantees. Suitable for Temps up to 35c.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													OPTION 5: PROFLEX - EVAFLEX. Highly durable high UV resistance for commercial pools and warmer temperatures. Available in sold colours. 20 YEAR WARRANTY with stain resistance and chlorine lightfastness guarantees. Suitable for Temps up to 37c.
												</div >&nbsp;


											</div>
											)}

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="heating">Heating System</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notheat(!heat)}></input>
												</div>
											</div>

											<select {...register("heating")}>
												<option value="ASHP">Air Source Heat Pump</option>
												<option value="LTHW - Heat ex">Heat Exchanger</option>
											</select>

											{heat && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Air Source Heat Pumps
												</div >&nbsp;
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Most pool water is heated using air source heat pumps that can act as the sole source of heat. These
													pumps are designed to maintain water temperature at 28c within pre-set air temperature ranges.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													The type of heat pump required for your pool depends on whether your pool is indoor or outdoor
													and the period your pool is in use.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Heat pumps are available for operation during the summer season or all year round.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Summer Season Heat Pump: The summer season heat pump is designed for outdoor swimming
													pools and covers the months from April to September after which the pool is winterised requiring
													very little heat input. A summer season air source heat pump will be sufficient to retain pool temp
													at 28C where the air temp ranges between x – y C. Outside of these ranges an all year round heat
													pump or gas or oil boiler source will be required if you plan to use your pool during this period
													select summer
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													All Year Round Heat Pump: If you wish to have your outdoor pool open all year round an all year
													round heat pump which can maintain pool temperature at 28C where the air temperature ranges
													from x-y C or a gas or oil fired boiler or a combination of both will be required.
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Indoor pools will be used all year round and will require an all year round heat pump unless there is
													supplementary or alternative heat sources such as renewable, gas or oil fired boilers that are used in
													the colder months.
												</div >&nbsp;&nbsp;&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Heat Exchangers
												</div >&nbsp;

												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													If you plan to heat your pool using existing heating resources you will require a heat exchanger. It
													will be important to establish that your existing gas or oil fired boiler has sufficient capacity to meet
													the heating load.
												</div >&nbsp;
											</div>
											)}

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="uv">UV System</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notuv(!uv)}></input>
												</div>
											</div>

											<select {...register("UV")}>
												<option value="no">No</option>
												<option value="yes">Yes</option>
											</select>

											{uv && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													A UV pool system installed in your plant room uses ultra violet light to target harmful bacteria so they cant reproduce and then kills them. It is not a chemically free system but when combined  with chlorine at  0.5 parts per million is recognised by the WHO as effective. With UV you will reduce the amount of chemicals you use and it is highly effective when deployed together with an automatic dosing system which accurately monitors and doses the water to achieve the minimum chemical input required to keep your pool  a safe environment.
												</div >&nbsp;
											</div>
											)}

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="dosing">Chemical Dosing</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notdose(!dose)}></input>
												</div>
											</div>

											<select {...register("dosing")}>
												<option value="Manual">Manual (Inline)</option>
												<option value="Watersens Free Chlorine / PH Net">Automated - Standard</option>
												<option value="Pool Manager PRO">Automated - Premium</option>
												<option value="ANALYT 2 PM5">Automated - Professional</option>
											</select>

											{dose && (<div>
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													In line dosing with pucks of chlorine is an option but it has its limitations in that it requires the user to regularly manually take readings and often results in under and over dosing of the pool.
												</div >&nbsp;
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Automated dosing monitors the water constantly providing instant readings and alerts that can be accessed remotely via wifi. The system injects the minimum chlorine required to keep the pool safe and in chemical balance. When the  automated dosing system is combined with UV  you will reduce the amount of chemicals used.
												</div >&nbsp;
												<div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													Details of the specification of each of the options available can be obtained here.
												</div >&nbsp;
											</div>
											)}

											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<div>
													<label style={{ fontWeight: 'bold', fontSize: 20, color: 'white' }} htmlFor="cover">Cover System</label>
												</div>&nbsp;
												<div>
													<input type='button' value={'Info'} style={{ padding: 0, marginTop: 25, height: 25, width: 50, textAlign: 'center' }} onClick={() => notcover(!cover)}></input>
												</div>
											</div>

											<select {...register("cover")}>
												<option onClick={() => setgear(0)}value="GeoBubble Solar Cover with hemming ">GeoBubble Cover</option>
												<option onClick={() => setgear(1)} value="Foam Super cover 5mm ">Blanket Cover</option>
												<option onClick={() => setgear(2)} value="slatted">Slatted Cover</option>
											</select>
											
											{(gear==0 || gear==2) && (<div>
												<select {...register("gear")}>
												{gear==0 && <option value="Manual">Manual</option>}
												{gear==2 && <option value="Auto">Automatic</option>}
											</select>
											</div>
											)}
											
											{gear==1 && (<div>
												<select {...register("gear")}>
												<option value="Auto">Automatic</option>
												<option value="Manual">Manual</option>
											</select>
											</div>
											)}
											{(gear==0 || gear==1) && (<div>
												<select {...register("coverpos")}>
												<option onClick={() => setcave(false)}value="Deck">Deck Level</option>
											</select>
											</div>
											)}
											{gear==2 && (<div>
												<select {...register("coverpos")}>
												<option onClick={() => setcave(false)}value="Deck">Deck Level</option>
												<option onClick={() => setcave(true)}value="Cave">Cave System</option>
												<option onClick={() => setcave(false)}value="Bench">Bench System</option>
											</select>
											</div>
											)}
											{gear==2 && (<div>
												<select {...register("material")}>
												<option onClick={() => setpvc(true)}value="PVC">PVC</option>
												<option onClick={() => setpvc(false)}value="Polycarbonate">Poly</option>
												
											</select>
											</div>
											)}
											{gear==2 && (<div>
												<select {...register("colour")}>
												{pvc && <option value="White">White</option>}
												{pvc && <option value="Solar">Solar</option>}
												{pvc && <option value="Grey">Grey</option>}
												{pvc && <option value="Transparent">Transparent</option>}
												{!pvc && <option value="Metallic">Metallic</option>}
												{!pvc && <option value="Solar/Transparent">Solar</option>}
												{!pvc && <option value="Transparent">Transparent</option>}
												{!pvc && <option value="Platinum Solar ">Platinum Solar</option>}
											</select>
											</div>
											)}

											<input type="submit" onClick={() => notintro(false) && setLoading(!isLoading)} />
											<input type="button" value="View Estimate" onClick={viewQuote}></input>
										</form>}
									</div>
								</div>}
								{intro && <div style={{ textAlign: 'center', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<PDFViewer width="1000" height="600" className="app" >
										<Invoice invoice={pool} />
									</PDFViewer>
								</div>}
							</div>}
					/>

				</Routes>
			</>
		</div>
	);
}

export default App;


