import React from "react";
import {View, StyleSheet,Text } from '@react-pdf/renderer';
const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 50
const COL2_WIDTH = 25
const COLN_WIDTH = 25
const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginTop: 20
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableCol2Header: { 
      width: COL2_WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },  
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol2: { 
      width: COL2_WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },   
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  }
});

const Kits = ({data}) => (
      <View>
            <Text>Maintainance Kit Contents</Text>
      <View style={styles.table}> 
        {data.Kit[0] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1Header}> 
            <Text style={styles.tableCellHeader}>Product</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Quantity</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Cost</Text> 
          </View> 
        </View>}
        {data.Kit[0] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[0]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[0]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
               <Text style={styles.tableCell}>{data.Kit[0][' Cost  ']}</Text> 
          </View> 
        </View>}
        {data.Kit[1] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[1]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[1]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
               <Text style={styles.tableCell}>{data.Kit[1][' Cost  ']}</Text> 
          </View> 
        </View>} 
        {data.Kit[2] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[2]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[2]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
               <Text style={styles.tableCell}>{data.Kit[2][" Cost  "]}</Text> 
          </View> 
        </View>} 
        {data.Kit[3] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[3]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[3]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[3][" Cost  "]}</Text> 
          </View> 
        </View>} 
        {data.Kit[4] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[4]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[4]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[4][" Cost  "]}</Text> 
          </View> 
        </View>}
        {data.Kit[5] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[5]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[5]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[5][" Cost  "]}</Text> 
          </View> 
        </View>} 
        {data.Kit[6] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[6]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[6]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[6][" Cost  "]}</Text> 
          </View> 
        </View>} 
        {data.Kit[7] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[7]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[7]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[7][" Cost  "]}</Text> 
          </View> 
        </View>} 
        {data.Kit[8] && <View style={styles.tableRow}>
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[8]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[8]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[8][" Cost  "]}</Text> 
          </View> 
        </View>} 
        {data.Kit[9] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[9]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[9]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[9][" Cost  "]}</Text> 
          </View> 
        </View>} 
        {data.Kit[10] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[10]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[10]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[10][" Cost  "]}</Text> 
          </View> 
        </View>} 
        {data.Kit[11] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[11]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[11]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[11][" Cost  "]}</Text> 
          </View> 
        </View>}
        {data.Kit[12] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[12]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[12]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[12][" Cost  "]}</Text> 
          </View> 
        </View>}
        {data.Kit[13] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>{data.Kit[13]['Description']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[13]['Quantity ']}</Text> 
          </View> 
          <View style={styles.tableCol}> 
                <Text style={styles.tableCell}>{data.Kit[13][" Cost  "]}</Text> 
          </View> 
        </View>}

      </View>
      </View>
      
);

export default Kits;