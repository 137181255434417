import React from "react";
import {View, StyleSheet,Text } from '@react-pdf/renderer';
const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 50
const COL2_WIDTH = 50

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0,
    marginTop: 10,
    marginBottom:20
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableCol2Header: { 
      width: COL2_WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderBottomColor: '#000',
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },  
 
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableCol2: { 
      width: COL2_WIDTH + '%', 
      borderStyle: BORDER_STYLE, 
      borderColor: BORDER_COLOR,
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    },   
 
  tableCellHeader: {
    margin: 5, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  },
  tableCellTotal: { 
      margin: 5, 
      fontSize: 10,
      fontWeight:1000
    }
});

const Sum = ({data}) => (
      <View>
            <Text style={'fontWeight:bold'}>Quote Summary</Text>
      <View style={styles.table}> 
        {data.Kit[0] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1Header}> 
            <Text style={styles.tableCellHeader}>Module</Text> 
          </View> 
          <View style={styles.tableCol2Header}> 
            <Text style={styles.tableCellHeader}>Cost</Text> 
          </View> 
        </View>}
       
      
        {(data.Pool['SERIES '] == 'Series 1.1') && data.Pipe[21] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Pool Shell and Features</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.Pipe[21].Cost + data.Lining['Total Cost'] + data.Access.Cost + data.Pool[" Manufacture Cost inc Fittings  "])}</Text> 
          </View>
        </View>}
        {(data.Pool['SERIES '] == 'Series 1.1') && !data.Pipe[21] && data.Pipe[14] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Pool Shell and Features</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.Pipe[14].Cost + data.Lining['Total Cost'] + data.Access.Cost + data.Pool[" Manufacture Cost inc Fittings  "])}</Text> 
          </View>
        </View>}



        {(data.Pool['SERIES '] == 'Series 3.1') && data.Pipe[21] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Pool Shell and Features</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.Pipe[21].Cost + data.Lining['Total Cost'] + data.Access.Cost + data.Pool[" Manufacture Cost "])}</Text> 
          </View>
        </View>}
        {(data.Pool['SERIES '] == 'Series 3.1') && !data.Pipe[21] && data.Pipe[14] && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Pool Shell and Features</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.Pipe[14].Cost + data.Lining['Total Cost'] + data.Access.Cost + data.Pool[" Manufacture Cost "])}</Text> 
          </View>
        </View>}
        
        {<View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Maintainance Kit</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.Kit[13][' Cost  '])}</Text> 
          </View>
        </View>}
        {(data.Pool['SERIES '] == 'Series 1.1') && data.cover && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Cover System</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.motor.Cost + (data.Pool["Surface Area M^2"] * data.cover['Price m^2']))}</Text> 
          </View>
        </View>}
        {(data.Pool['SERIES '] == 'Series 3.1') && data.cover && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Cover System</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.motor.Cost + ((data.Pool["WIDTH "] * data.Pool["Length "]) * data.cover['Price m^2']))}</Text> 
          </View>
        </View>}
        {!data.uv && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Plant Room</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.heating[' Cost '] + data.Filter[' FILTER COST '] + data.Pump[' Cost '])}</Text> 
          </View>
        </View>}
        {data.uv && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Plant Room</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.uv[' COST '] + data.heating[' Cost '] + data.Filter[' FILTER COST '] + data.Pump[' Cost '])}</Text> 
          </View>
        </View>}

        {data.uv && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCell}>Total Quote Cost</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCell}>{Math.round(data.Pipe[14].Cost + data.Lining['Total Cost'] + data.Access.Cost + data.Pool[" Manufacture Cost inc Fittings  "]
                                                + data.Kit[13][' Cost  ']
                                                + data.motor.Cost + (data.Pool["Surface Area M^2"] * data.cover['Price m^2']) 
                                                + data.uv[' COST '] + data.heating[' Cost '] + data.Filter[' FILTER COST '] + data.Pump[' Cost '])}</Text> 
          </View>
        </View>}
        {(data.Pool['SERIES '] == 'Series 1.1') && data.cover && !data.uv && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCellTotal}>Total Quote Cost</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCellTotal}>{Math.round(data.Pipe[14].Cost + data.Lining['Total Cost'] + data.Access.Cost + data.Pool[" Manufacture Cost inc Fittings  "]
                                                + data.Kit[13][' Cost  ']
                                                + data.motor.Cost + (data.Pool["Surface Area M^2"] * data.cover['Price m^2']) 
                                                + data.heating[' Cost '] + data.Filter[' FILTER COST '] + data.Pump[' Cost '])}</Text> 
          </View>
        </View>}
        {(data.Pool['SERIES '] == 'Series 3.1') && data.cover && !data.uv && <View style={styles.tableRow}> 
          <View style={styles.tableCol1}> 
                <Text style={styles.tableCellTotal}>Total Quote Cost</Text> 
          </View> 
          <View style={styles.tableCol2}> 
                <Text style={styles.tableCellTotal}>{Math.round(data.Pipe[14].Cost + data.Lining['Total Cost'] + data.Access.Cost + data.Pool[" Manufacture Cost "]
                                                + data.Kit[13][' Cost  ']
                                                + data.motor.Cost + ((data.Pool["WIDTH "] * data.Pool["Length "]) * data.cover['Price m^2']) 
                                                + data.heating[' Cost '] + data.Filter[' FILTER COST '] + data.Pump[' Cost '])}</Text> 
          </View>
        </View>}
        
        


      </View>
      </View>
      
);

export default Sum;