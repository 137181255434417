import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    businessContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    headerContainer: {
        marginTop: 36
    },
    overall: {
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    billTo: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 125
    }
  });

  

const today = new Date()
const dated = today.getDate() + '-' + (today.getMonth() +1)  + '-' + today.getFullYear();


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

var date = new Date();
var next = date.addDays(30);
const expiry = next.getDate() + '-' + (next.getMonth() +1)  + '-' + next.getFullYear();

  const BillTo = ({invoice}) => (
    <View style={styles.overall}>
        <View style={styles.headerContainer}>
            <Text style={styles.billTo}>Estimate for:</Text>
            {invoice['firstname'] && <Text>{invoice['firstname']} {invoice['lastname']}</Text>}
            {invoice['number'] && <Text>{invoice['number']}</Text>}
            {invoice['email'] && <Text>{invoice['email']}</Text>}
            <Text style={styles.label}>Quote Date: {dated}</Text>
            <Text style={styles.label}>Expiry Date: {expiry}</Text>
        </View>
        <View style={styles.headerContainer}>
            <Text style={styles.billTo}> </Text>
            
        </View>
        <View>
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>12e Woodside Road</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Business Park</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Woodside Road Ind Est</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Ballymena</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Co. Antrim</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>BT42 4QJ</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Tel: 02825 446 116</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>E: info@castillopools.com</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>W: www.castillopools.com</Text>
            </View >
        </View>
    </View>
    
    
  );
  
  export default BillTo