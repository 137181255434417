// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyD9xdGnu2k2aCGRl5buFlL7eszxrRo82ks",

  authDomain: "castillo-5f594.firebaseapp.com",

  projectId: "castillo-5f594",

  storageBucket: "castillo-5f594.appspot.com",

  messagingSenderId: "743134626749",

  appId: "1:743134626749:web:14e4a49a149453d6ef88d4"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);